.header {
  margin-bottom: 20px;
}

.card {
  padding: 30px 0;
}

.name {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1rem;
}

.details {
  margin: auto 0;
}

.value {
  line-height: 1rem;
  font-size: 1rem;
  margin:15px 0;
}

.type {
  text-transform: uppercase;
  font-size: .8rem;
  opacity: .7;
}

.address {
  font-style: normal;
  line-height: 1rem;
  font-size: 1rem;
  margin:15px 0;
}

