.wrapper {
  display: block;
  text-decoration: none;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, .05);
}

.wrapper:first-of-type {
  border-top: none;
}

.name {
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 900;
}

.detail {
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin: 6px 0;
  color: #555;  
  text-transform: uppercase;
}

.detail span {
  color:#999;
}