.wrapper {
  min-height: 100vh;
}

.controls {
  margin: 30px 0 60px;
}

.title  {
  font-weight: 500;
  font-size: 1.2rem;
}
.group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.card {
  margin-bottom: 60px;
}