.wrapper {
  margin: 30px 0;
}

.label {
  display: block;
  color: #555;
  font-size: 0.8rem;
  line-height: 1rem; 
}

.textarea {
  margin-top: 4px;
  border-radius: 4px; 
  padding: 12px;
  border: 2px solid;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .05);
  box-sizing: border-box;
}