.grid{
  display: grid;
  min-height: 80vh;
  align-content: center;
}
.card {
  text-align: center;
}

.title {
  font-size: 2rem;
}
.buttons {
  display:grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  width: fit-content;
  margin: 40px auto 5px;
}