body {
  margin: 0;
  padding: 0;
  background-color: #faf9f8;
}

section {
  margin: 40px 0;
}

.m-15 {
  margin: 15px;
}

.section-description {
  margin: 2px 0 4px;
  font-size: 0.86rem;
  color: #777;
}