.wrapper {
  display: block;
  text-decoration: none;
  padding: 8px 0;
}


.name {
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin: 0;
  margin-bottom: 4px;
  font-weight: 900;
  text-transform: uppercase;
}

.address {
  font-size: 0.9rem;
  line-height: 1.2rem;
  margin: 0;
  color: #777;
  text-transform: uppercase;
}

.link {
  color: #fbab7e;
  font-size: .8rem;
  padding: .6rem 0;
  text-decoration: none;
}