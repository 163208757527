.card {
    background-color: #fff;
    display: block;
    padding: 16px;
    margin: 0;
    border-radius: 4px;
    background-color: #dfe3f0;
}

.title {
  font-size: 0.92rem;
  color: #333;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1rem;
}

.detail {
  color: #F7CE68;
  font-size: 0.9rem;
  line-height: 1.2rem;
}

