.card { 
  background-color:#fff;
  border-radius: 20px;
  display:block;
  padding: 26px 20px;
  margin: 8px;
  text-decoration: none;
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
}

.illustration {
  border-radius: 50%;
  margin-bottom: 52px;
  width: fit-content;
  background-color: #fff;
}

.title {
  font-size: 0.92rem;
  color: #333;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1rem;
}

.detail {
  color: #F7CE68;
  font-size: 0.9rem;
  line-height: 1.2rem;
}

