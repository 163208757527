.grid {
  margin: 60px auto;
  display: grid;
  max-width: 800px;
  grid-template-columns: 1fr;
  align-items: center;
  vertical-align: center;
}

.title {
  margin: 0 0 5px;
  font-size: 2.2rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-bottom:20px;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.information {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  padding: 24px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
}

.bpn {
  color: #777;
}

.footnote {
  color: #ccc;
  text-align: center;
  margin: 80px auto 40px;
}
.logo {
  max-width: 300px;
  margin: 30px 0;
}

.header address {
  font-style: normal;
}

.invoicedetails {
  text-align: right;
}

.cost {
  margin:20px 0;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: underline ;
  text-decoration-line:2px solid #333; ;
  color: #000;
  margin-bottom: 36px;
}

.description {
  margin: 0 0 40px;
}

.date {
  font-size: 0.8rem;
  line-height: 1rem;
  margin:4px 0;
  text-transform: uppercase;
}

.map {
  border: 1px solid #000;
  border-radius: 4px;
  height: 100%;
  display: grid;
  box-sizing: border-box;
  margin: 0;
} 

.details {
  padding:0 40px;
}

.meta {
  margin:20px 0 0;
  font-size: .9rem;
  line-height: .8rem;
}

.notes  {
  margin-top: 100px;
}

.buttons {
  display:grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  width: fit-content;
  margin-top: 40px;
}