.wrapper {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  display: grid;
  grid-template-columns:1fr auto auto auto auto auto;
  padding:12px;
}
.link {
  text-decoration: none;
  font-size: 14px;
  line-height: 3rem;
  padding:0 20px;
  display:inline-block;
  color: #333;
}

.logo {
  height: 2.6rem;
  padding: .2rem;
}
.active {
  border-bottom: 3px solid #f7941d;
  color: #f7941d;
}

.social > img {
  display: inline-block;
    height: 1.8rem;
    padding: .6rem;
}


@media screen and (max-width: 450px) {

    .logo {
        height: 2rem;
        padding: .1rem;
    }

    .social {
        display: none;
    }
    .link {
        display: none;
    }
}