.wrapper {
  margin: 0 auto;
  padding: 20px;
}

.image {
  border-radius: 50%;
  max-width: 100px;
  display: block;
  margin: auto;
}