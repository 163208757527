.grid {
  margin: 60px auto;
  display: grid;
  max-width: 760px;
  grid-template-columns: 1fr;
  align-items: center;
  vertical-align: center;
}

.description {
  margin: 0 0 40px;
  color: #777;
}

.map {
  border-radius: 4px;
  height: 100%;
  display: grid;
  box-sizing: border-box;
} 

.card {
  padding: 40px 68px;
  margin:0;
  border-radius: 4px;
  background-color: #dfe3f0;
}

.buttons {
  display:grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  width: fit-content;
  margin-top: 40px;
}