.wrapper {
  display: block;
  text-decoration: none;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, .05);
}

.wrapper:first-of-type {
  border-top: none;
}

.cargo {
  font-size: 0.9rem;
  line-height: 1.8rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}

.details {
  font-size: 0.86rem;
  line-height: 1.3rem;
  margin: 18px 0;
  color: #333;  
}
.date {
  color:#999;
  font-size: 0.7rem;
  text-transform: uppercase;

}
.details span {
  color:#888;
}

.tripId {
  display: inline-block;
  color: #555;
  font-size: 0.8rem;
  margin-bottom:1.2rem;
}

.link {
  text-decoration: none;
  color: #f7941d;
  display: block;
  margin: 20px 0 0;
}