.wrapper {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}
.link {
  text-decoration: none;
  font-size: 1rem;
  line-height: 3rem;
  padding:0 30px;
  display:inline-block;
}

.active {
  border-bottom: 3px solid #f7941d;
  color: #f7941d;
}