.wrapper {
  display: block;
  margin: 40px auto;
  width: 60%;
  max-width: 400px;
}

.image {
  width: 100%;
}

@media only screen and (max-width: 800px){
  .wrapper {  
    max-width: none;
  }
}