.header {
  text-align: center;
  padding: 12px;
  color: #fff;
  background-color: #f7941d;
  display: grid;
  grid-template-columns:1fr auto auto;
  align-items: center;
} 

.bars{
  background-color: transparent;
  margin: 0;
  border:none;
  outline: none;
} 

.bars > img {
  height: 1.4rem;
}

.title {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 0;
}

.tagline {
  font-size: 0.7rem;
  text-transform: uppercase;
  margin: 0;
}

.logout {
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
  background-color: transparent;
  margin: 0 4px;
  font-size: .9rem;
  line-height: 1.2rem;
  font-family: 'Khula', sans-serif;
  border-radius: 4px;
  padding: 10px;
  display: block;
}

@media only screen and (max-width: 800px){
  .title {
    line-height: 1.4rem;
  }
}