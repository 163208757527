.container {
  min-height: 100vh;
}

.wrapper {
  display: block;
}

.main {
  background-color: #fff;
  color: #3f3f3f;
  margin:20px;
  padding: 60px 20px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,0.132), 0 0.3px 0.9px 0 rgba(0,0,0,0.108);
}