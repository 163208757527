.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 60px;
  background: url("/images/open-road.jpeg");
  background-size: cover;
  filter: grayscale(20%);
  padding-bottom: 60px;
}

.container {
  padding: 25px;
} 

.icon {
  height: 6rem;
  display: block;
  padding: 1rem;
  margin: auto;
}

.option {
  display: block;
  margin: auto;
  text-align: center;
  text-decoration: none;
  color: #333;
}

.header {
  color: #fff;
  background-color: #f7941d;
  padding:5px 30px;
}

.title {
  margin:0;
  line-height: 3.2rem;
  font-size: 1.8rem;
}

.heading{
  font-size: 1rem;
  color: #333;
  line-height: 1.2rem;
}
.card {
  background-color: #fff;
  padding: 48px 40px;
  max-width: 400px;
  min-width: 36%;
  margin: auto;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
}

.page {
  background-color: #fff;
  padding: 48px 20px;
  max-width: 1200px;
  margin: auto;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.buttons {
  padding-top: 30px;
  display:grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  width: fit-content;
}

.instruction {
  color: #555;
  font-size: 0.9rem;
  margin: 30px 0 10px;
}

.instruction a {
  text-decoration: none;
  color: #fbab7e;
}

.instruction a:hover {
  color: red;
}


@media screen and (max-width: 450px) {
    .card {
        background-color: #fff;
        margin:auto 20px;
        padding: 30px; 
    }
}