.wrapper {
  min-height: 100vh;
}

.grid {
  margin: 60px auto;
  display: grid;
  max-width: 760px;
  grid-template-columns: 1fr;
  align-items: center;
  vertical-align: center;
}

.title {
  margin: 0 0 5px;
  font-size: 1.4rem;
}

.description {
  margin: 0 0 40px;
  color: #777;
}


.upload {
  border: 1px dashed #f7941d;
  border-radius: 4px;
  display: grid;
  padding:40px 0;
  box-sizing: border-box;
} 

.group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin: 15px 0;
}

